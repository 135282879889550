
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

body {
  background-color: #d9d9d9;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}

.fc-toolbar h2 {
  color: #3a6168;
  font-size: 1rem;
  font-weight: 500;
}

.fc-button {
  padding: 0.4rem;
}
